<template>
  <p>
    <el-pagination
      background
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="i => $emit('change', i)"
      layout="prev, pager, next"
      :pager-count="5"
      :total="total">
    </el-pagination>
  </p>
</template>

<script>
export default {
  name: "pagenation",
  props: {
    total: Number,
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    }
  }
};
</script>


<style scoped>
.pg-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.pg-btn + .pg-btn {
  margin-left: 10px;
}
.disabled {
  background: #eee;
  cursor: not-allowed;
}
.active {
  background: #e7393e;
  border-color: #e7393e;
  color: #fff;
}
</style>
