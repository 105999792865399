<template>
  <div>
    <div class="container">
      <div v-if="route.params.keyWord" class="top-title">
        <b>"{{route.params.keyWord}}"</b> 课程的搜索结果
      </div>
      <p class="title">
        全部课程
      </p>
      <div v-loading.fullscreen.lock="loading" class="container-wrapper">
        <div class="p-c container">
          <el-row :gutter="24" v-if="videos.length">
            <el-col class="v-item" v-for="v in videos" :key="v.id" :xs="{span: '12'}" :sm="{span: '4-8'}" >
              <NewVideoItem :tag="v.mediaType === 'AUDIO'" :width="300" :video="v" />
            </el-col>
          </el-row>
          <span class="no-data" v-else>
            ...暂无数据
          </span>
        </div>
      </div>
      <p class="pagenation">
        <Pagenation @change="pageChange" :pageSize="20" :currentPage="cp"  :total="total" />
      </p>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { getVideoList as _getVideoList } from "@api"
import NewVideoItem from '@c/NewVideoItem.vue'
import Pagenation from '@c/Pagenation.vue'
import { reactive, toRefs, computed, watch } from "vue"
import { useRoute } from 'vue-router'
export default {
  name: "Audios",
  components: {
    NewVideoItem,
    Pagenation
  },
  setup () {
    const route = useRoute()
    console.log(route)
    const state = reactive({
      videos: [],
      currentPage: 0,
      total: 0,
      loading: false
    })

    const cp = computed(() => state.currentPage + 1)

    const getVideoPage = (refresh) => {
      state.loading = true
      _getVideoList({
        key: route.params.keyWord,
        currentPage: refresh ? state.currentPage = 0 : state.currentPage,
        pageSize: 20
      }).then(res => {
        state.videos = res.data.records
        state.total = res.data.total
      }).finally(() => {
        state.loading = false
      })
    }
    const pageChange = (pageNo) => {
      state.currentPage = pageNo - 1
      getVideoPage()
    }

    getVideoPage(true)

    watch(route, () => {
      getVideoPage(true)
    })


    return {
      ...toRefs(state),
      getVideoPage,
      pageChange,
      route,
      cp
    }
  }
};
</script>


<style scoped lang="stylus">

.container-wrapper {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;
  position: relative;
}


.pagenation {
  height: 50px;
  width: 100%;
  text-align: center;
}

.v-item {
  margin-bottom 30px
}

.p-c {
  min-height: 100%;
  position: relative;

  .no-data {
    top: 40%;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    color: #999;
  }
}
.title {
  font-size: 22px;
  line-height: 40px;
  font-family: '微软雅黑';
  margin-top: 25px;
  margin-bottom: 15px;
}
.top-title {
  color: #272727;
  font-size: 18px;
  margin-top: 25px;
}
@media screen and (max-width: 767px) {
  .el-col-xs-12.el-col-sm-4-8 {
    display: block;
    max-width: 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
  }
} 
</style>
